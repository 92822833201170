<script>
import { useStore } from 'vuex'
import { onMounted, reactive, toRefs } from 'vue'

const stepName = 'ReasonForLifeInsurance'

const keys = [
  'Protect a debt (ie. mortgage or medical bills)',
  'Cover end of life expenses such as funeral costs',
  'As income replacement',
  'Leave money for my family',
  'Leave money to an organization/charity',
  'Other'
]

const questionText = 'Why are you looking for life insurance?'

export default {
  name: stepName,

  emits: ['next-step'],

  setup(_, {emit}) {
    const store = useStore()

    const state = reactive({
      protect_debt: false,
      cover_end_of_life_expenses: false,
      income_replacement: false,
      leave_money_for_family: false,
      leave_money_for_charity: false,
      other: false
    })

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const setAnswer = () => {
      const fields = [
        {field: 'protected_debt', value: state.protect_debt ? 1 : 0},
        {field: 'cover_end_of_life_expenses', value: state.cover_end_of_life_expenses ? 1 : 0},
        {field: 'income_replacement', value: state.income_replacement ? 1 : 0},
        {field: 'leave_money_for_family', value: state.leave_money_for_family ? 1 : 0},
        {field: 'leave_money_for_charity', value: state.leave_money_for_charity ? 1 : 0},
        {field: 'other', value: state.other ? 1 : 0},
      ]

      store.commit('SET_FIELDS', fields)
      emit('next-step', stepName)
    }

    return {
      ...toRefs(state),
      questionText,
      keys,
      setAnswer
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="box">
        <h2 class="main-heading button-answers-heading">{{ questionText }}</h2>
      </div>
    </div>

    <div class="row checkbox-container-row">
      <div class="box">
        <div class="checkbox-container">
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="protect_debt">
            <label class="checkbox-label">{{keys[0]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="cover_end_of_life_expenses">
            <label class="checkbox-label">{{keys[1]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="income_replacement">
            <label class="checkbox-label">{{keys[2]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="leave_money_for_family">
            <label class="checkbox-label">{{keys[3]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="leave_money_for_charity">
            <label class="checkbox-label">{{keys[4]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="other">
            <label class="checkbox-label">{{keys[5]}}</label>
          </div>
        </div>

        <button
          class="main-button main-button-w mt-sm"
          @click="setAnswer"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/styles/component_styles/questionnaire/reason_for_life_insurance.scss';
</style>